// en.js
export default {
  ゲーム友達を募集するならgamee: 'Gamee - SNS for making great game friends! -',
  //Common
  戻る: 'Back',
  もっと見る: 'See more',
  最終更新: 'Last update',
  年: '.',
  月: '.',
  日: '',
  平日: 'Mon 〜 Fri',
  土日: 'Sat & Sun',
  時開始: '',
  時終了: " O'clock",
  時: " O'clock",
  //タブ
  ホーム: 'Home',
  募集掲示板: 'Find Team Mates',
  グループ一覧: 'Group Chats',
  性格診断: 'Personality Check',
  ゲーム友達募集: 'Auto Match',
  自己紹介カード: 'Profile Card',
  お知らせ: 'Notifications',
  マイページ: 'My page',
  診断ツール: 'Personality Check',
  //ヘッダー
  アプリ版gameeはサクサク動くし通知も受け取れるよ:
    'Gamee mobile app is very smooth. You can send messages and get notifications!',
  インストール: 'Download',
  ひらく: 'Open',
  新規登録: 'Register',
  ログイン: 'Login',
  ログアウト: 'Logout',
  //フッター
  利用規約: 'Terms of use',
  プライバシーポリシー: 'Privacy Policy',
  ご要望はこちらから: 'Inquiry',
  公式Twitter: 'Official Twitter',
  テンプレ提供はこちらから: 'To provide templates',
  ApexLegends自己紹介カード: 'ApexLegends Profile Card',
  Apexモバイル自己紹介カード: 'Apexモバイル Profile Card',
  VALORANT自己紹介カード: 'VALORANT Profile Card',
  FORTNITE自己紹介カード: 'FORTNITE Profile Card',
  DBD自己紹介カード: 'DBD Profile Card',
  AmongUs自己紹介カード: 'AmongUs Profile Card',
  モンハンライズ自己紹介カード: 'Monster Hunter Rise Profile Card',
  ポケモンユナイト自己紹介カード: 'Pokemon Unite Profile Card',
  フォールガイズ自己紹介カード: 'FallGuys Profile Card',
  スプラトゥーン3自己紹介カード: 'Splatoon3 Profile Card',
  原神自己紹介カード: 'Genshin Profile Card',
  Overwatch2自己紹介カード: 'Overwatch2 Profile Card',
  CoDMW2自己紹介カード: 'CoD:MW2 Profile Card',
  ガンエボ自己紹介カード: 'Gundam Evolution Profile Card',
  ヒロアカUR自己紹介カード: 'My Hero Academia Ultra Rumble Profile Card',
  //アプリDLモーダル
  アプリはサクサク動くし通知も受け取れるよ:
    '\\ Gamee mobile app is very smooth and you can send messages and get notifications! /',
  アプリをダウンロード: 'Download Gamee app',
  今はしない: 'Later',
  ゲーム友達を募集したりゲームに誘うための機能が盛りだくさん:
    'A lot of features for making game friends are available🥳🎈',
  //ホーム
  性格診断を始める: '性格診断を始める',
  武器診断を始める: '武器診断をはじめる',
  オンラインゲームの自己紹介カードが作成編集できる:
    '1. Create your cute profile card for online games.',
  今遊べるゲーム友達を自動で募集できる: '2. Find online gamers.',
  ゲーム募集掲示板で友達が探せる: '3. Talk with gamers in group room chats.',
  対応ゲーム近日追加予定: '※New games will be added soon',
  最近のゲーム募集: 'Recent posts',
  最近マッチしたルーム: 'Recent matched rooms',
  新着のユーザー: 'New users',
  自己紹介カードテンプレート: 'Profile card templates',
  新着の自己紹介カード: 'New profile cards',
  サービスをご利用いただくにはSNSログインが必要です:
    'SNS login is required to use this service.',
  さっそくゲーム募集をつくってみる: 'Find online gamers',
  さっそく自己紹介カードをつくる: 'Create profile card',
  ゲームを選んでください: 'Choose a game',
  自己紹介カードをつくるゲームを選んでください:
    'Choose a game to create profile card.',
  これでつくる: 'Confirm',
  キャンセル: 'Cancel',
  ピックアップグッズ: 'Picked up items',
  運営が選んだ本当にいいと思えるゲームグッズだけをピックアップしています:
    'We only pick up game goods that we think are really great, selected by Gamee team.',
  //マイページ
  プロフィールを編集: 'Edit profile',
  遊んでいるゲーム: 'Playing games',
  あなたの自己紹介カード: 'Your profile cards',
  //ログイン
  Appleでサインイン: 'Sign in with Apple',
  Twitterでサインイン: 'Sign in with Twitter',
  Googleでサインイン: 'Sign in with Google',
  //自己紹介カード詳細
  の自己紹介カード: "'s profile card",
  テンプレ制作: 'Template Card Creator',
  のTwitterをみる: "'s Twitter",
  のプロフィールをみる: "'s Gamee profile page",
  あなたの自己紹介カードをつくる: 'Create your profile card',
  //自己紹介カード作成ツール
  //Apex
  ApexLegendsかわいい自己紹介カード作成ツール:
    '【ApexLegends】Cute profile card generator【Season 13】',
  ApexLegendsかわいい自己紹介カード作成ツール説明文:
    'A tool to easily create and edit cute ApexLegends profile cards! 🥳🎉 Create your own profile cards by adding text while viewing previews!',
  //MyHeroAcademia
  ヒロアカURかわいい自己紹介カード作成ツール:
    '【My Hero Academia UR】Cute profile card generator',
  ヒロアカURかわいい自己紹介カード作成ツール説明文:
    'A tool to easily create and edit cute My Hero Academia UR profile cards! 🥳🎉 Create your own profile cards by adding text while viewing previews!',
  //BF2042
  BF2042かわいい自己紹介カード作成ツール:
    '【BF2042】Cute profile card generator',
  BF2042かわいい自己紹介カード作成ツール説明文:
    'A tool to easily create and edit cute BF2042 profile cards! 🥳🎉 Create your own profile cards by adding text while viewing previews!',
  //BlueProtocol
  BlueProtocolかわいい自己紹介カード作成ツール:
    '【BLUE PROTOCOL】Cute profile card generator',
  BlueProtocolかわいい自己紹介カード作成ツール説明文:
    'This is a tool that allows you to easily create and edit cute self-introduction cards for BlueProtocol (ブループロトコル)! 🥳🎉 Make your own self-introduction card while previewing the text input!',
  //Apexモバイル
  Apexモバイルかわいい自己紹介カード作成ツール:
    '【Apex Mobile】Cute profile card generator',
  Apexモバイルかわいい自己紹介カード作成ツール説明文:
    'A tool to easily create and edit cute Apex Mobile profile cards! 🥳🎉 Create your own profile cards by adding text while viewing previews!',
  //VALORANT
  VALORANTかわいい自己紹介カード作成ツール:
    '【VALORANT】Cute profile card generator',
  VALORANTかわいい自己紹介カード作成ツール説明文:
    'A tool to easily create and edit cute VALORANT profile cards! 🥳🎉 Create your own profile cards by adding text while viewing previews!',
  //DbD
  DbDかわいい自己紹介カード作成ツール: '【DbD】Cute profile card generator',
  DbDかわいい自己紹介カード作成ツール説明文:
    'A tool to easily create and edit cute DbD profile cards! 🥳🎉 Create your own profile cards by adding text while viewing previews!',
  //PokemonUnite
  PokemonUniteかわいい自己紹介カード作成ツール:
    '【Pokemon Unite】Cute profile card generator',
  PokemonUniteかわいい自己紹介カード作成ツール説明文:
    'A tool to easily create and edit cute Pokemon Unite profile cards! 🥳🎉 Create your own profile cards by adding text while viewing previews!',
  //Fortnite
  Fortniteかわいい自己紹介カード作成ツール:
    '【Fortnite】Cute profile card generator',
  Fortniteかわいい自己紹介カード作成ツール説明文:
    'A tool to easily create and edit cute Fortnite profile cards! 🥳🎉 Create your own profile cards by adding text while viewing previews!',
  //FallGuys
  FallGuysかわいい自己紹介カード作成ツール:
    '【Fall Guys】Cute profile card generator',
  FallGuysかわいい自己紹介カード作成ツール説明文:
    'A tool to easily create and edit cute Fall Guys profile cards! 🥳🎉 Create your own profile cards by adding text while viewing previews!',
  //Splatoon3
  Splatoon3かわいい自己紹介カード作成ツール:
    '【Splatoon3】Cute profile card generator',
  Splatoon3かわいい自己紹介カード作成ツール説明文:
    'A tool to easily create and edit cute Splatoon3 profile cards! 🥳🎉 Create your own profile cards by adding text while viewing previews!',
  //原神
  原神かわいい自己紹介カード作成ツール:
    '【Genshin】Cute profile card generator',
  原神かわいい自己紹介カード作成ツール説明文:
    'A tool to easily create and edit cute Genshin profile cards! 🥳🎉 Create your own profile cards by adding text while viewing previews!',
  //Overwatch2
  Overwatch2かわいい自己紹介カード作成ツール:
    '【Overwatch2】Cute profile card generator',
  Overwatch2かわいい自己紹介カード作成ツール説明文:
    'A tool to easily create and edit cute Overwatch2 profile cards! 🥳🎉 Create your own profile cards by adding text while viewing previews!',
  //CoDMW2
  CoDMW2かわいい自己紹介カード作成ツール:
    '【CoDMW2】Cute profile card generator',
  CoDMW2かわいい自己紹介カード作成ツール説明文:
    'A tool to easily create and edit cute CoDMW2 profile cards! 🥳🎉 Create your own profile cards by adding text while viewing previews!',
  //CoDMW3
  CoDMW3かわいい自己紹介カード作成ツール:
    '【CoDMW3】Cute profile card generator',
  CoDMW3かわいい自己紹介カード作成ツール説明文:
    'A tool to easily create and edit cute CoDBo6 profile cards! 🥳🎉 Create your own profile cards by adding text while viewing previews!',
  //CoDBo6
  CoDBo6かわいい自己紹介カード作成ツール:
    '【CoDBo6】Cute profile card generator',
  CoDBo6かわいい自己紹介カード作成ツール説明文:
    'A tool to easily create and edit cute CoDBo6 profile cards! 🥳🎉 Create your own profile cards by adding text while viewing previews!',
  //パズドラ
  パズドラかわいい自己紹介カード作成ツール:
    '【Pazudora】Cute profile card generator',
  パズドラかわいい自己紹介カード作成ツール説明文:
    'A tool to easily create and edit cute Pazudora profile cards! 🥳🎉 Create your own profile cards by adding text while viewing previews!',
  //ガンエボ
  ガンエボかわいい自己紹介カード作成ツール:
    '【GUNDAM EVOLUTION】Cute profile card generator',
  ガンエボかわいい自己紹介カード作成ツール説明文:
    'A tool to easily create and edit cute GUNDAM EVOLUTION profile cards! 🥳🎉 Create your own profile cards by adding text while viewing previews!',
  //AmongUs
  AmongUsかわいい自己紹介カード作成ツール:
    '【AmongUs】Cute profile card generator',
  AmongUsかわいい自己紹介カード作成ツール説明文:
    'A tool to easily create and edit cute AmongUs profile cards! 🥳🎉 Create your own profile cards by adding text while viewing previews!',
  //説明
  いろんな種類の中から好きなカードを選ぼう:
    '① Choose your favorite card from a variety of types!',
  名前やIDよく使うキャラなどあなたのことがみんなにわかるように項目を埋めよう:
    '② Fill in the fields with your name, ID...etc. so that everyone knows who you are!',
  完成したらXにシェアしてみよう友だちが見つかりやすくなるよ:
    "③ Share it on Twitter! It'll be easier to find your friends!",
  推奨環境: 'Recommended environment: Chrome and Safari.',
  プライベートブラウザやアドブロッカーを有効にしているとカードの作成に失敗することがあります:
    '※Private mode browser and ad blocker enabled browser are not supported.😢',
  テンプレートを選ぶ: 'Choose template',
  カードの背景色を選ぶ: 'Choose card color',
  文字色を選ぶ: 'Choose text color',
  フォントを選ぶ: 'Choose text font',
  アイコン画像: 'Profile Image',
  なまえ: 'Name',
  ID: 'ID',
  性別: 'Gender',
  プラットフォーム: 'Platform',
  招待スタイル: 'Invite style',
  募集スタイル: 'Check',
  プレイスタイル: 'Play style',
  ボイスチャット: 'Voice chat',
  ボイスチャットその他: 'Voice chat (other)',
  クラフト: 'Craft Level', //Fortnite
  プレイ時間帯平日: 'Play time (weekday)',
  プレイ時間帯土日: 'Play time (weekend)',
  プレイ時間帯自由記述: 'Play time (free description)',
  から: 'to',
  好きな武器: 'Favorite Weapon',
  好きなマップ: 'Favorite Map',
  ロール: 'Role',
  好きなブキ: 'Favorite Weapon',
  はじめた時期: 'Starting Season',
  世界ランク: 'World Rank',
  活動日数: 'Active period',
  よく使うレジェンド: 'Favorite Legends',
  よく使うエージェント: 'Favorite Agents',
  よく使うキャラ: 'Favorite Characters',
  よく使うヒーロー: 'Favorite Hero',
  好きなクルーの色: 'Favorite Crew Color',
  使用キラー: 'Favorite Killers',
  使用サバイバー: 'Favorite Survivors',
  KD: 'K/D',
  最高ダメージ: 'Highest Damage',
  最高ランク: '最高ランク',
  目標ランク: 'Target Rank',
  クラン: 'Clan',
  使用機体: 'Favorite Machines',
  ゲームモード: 'Game Mode',
  ランク: 'Rank',
  アリーナランク: 'Arena rank',
  チーム: 'Team', //Fortnite
  好き放題言っちゃおう: 'Butter up to EA！',
  ひとこと: 'Free',
  カードの作成にはSNS連携が必要です次の画面でログインをします:
    '\\ Twitter login is required to create profile card. Login on the next screen. /',
  これでカードをつくる: 'Create profile card',
  /* ランク */
  //APEX
  ルーキー: 'Rookie',
  ブロンズ: 'Bronze',
  シルバー: 'Silver',
  ゴールド: 'Gold',
  プラチナ: 'Platinum',
  ダイヤ: 'Diamond',
  アセンダント: 'Ascendant',
  マスター: 'Master',
  プレデター: 'Predator',
  //VALORANT
  アイアン: 'Iron',
  // ブロンズ: 'Bronze',
  // シルバー: 'Silver',
  // ゴールド: 'Gold',
  // プラチナ: 'Platinum',
  // ダイヤ: 'Diamond',
  イモータル: 'Immortal',
  レディアント: 'Radiant',
  //Fortnite
  できない: 'Not good',
  少しできる: 'A little',
  できる方: 'Good',
  建築職人: 'Professional',
  //PokemonUnite
  ビギナー: 'Beginner',
  スーパー: 'Super',
  ハイパー: 'Hyper',
  エリート: 'Elite',
  エキスパート: 'Expert',
  // マスター: 'Master', //Duplicate
  //Overwatch2
  // ブロンズ: 'Bronze',
  // シルバー: 'Silver',
  // ゴールド: 'Gold',
  // プラチナ: 'Platinum',
  // ダイヤモンド: 'Diamond',
  // マスター: 'Master',
  グランドマスター: 'Grand Master',
  TOP500: 'TOP500',
  オーナー: 'Owner',
}
